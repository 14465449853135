@import "/src/scss/variables";

/* #Hero
================================================== */
.hero {
    display: flex;
    top: 0;
    align-items: center;
    font-family: $font-family;
    padding-top: 50px;

    .container {
        width: 100%;
        max-width: 100%;

        & .col-lg-6:first-child {
            padding-left: 10%;
        }
    }

    .title {
        font-size: 82px;
        font-weight: 300;
        line-height: 90px;
        letter-spacing: 0px;
    }

    .subtitle {
        line-height: 32px;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 3rem;
        font-style: italic;
        color: #999;
    }

    .description {
        line-height: 32px;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 3rem;
        width: 70%;
    }

    .hero-img {
        background-size: 170%;
        background-position-y: 29%;
        background-position-x: 90%;
        height: 868px;
        width: 868px;
    }
}