.icon-box {
    padding: 40px 0;
}

.about-3 {
    margin-top: 40px !important;
}

@media (max-width: 767px) {
    .about-3 { margin-top: 40px !important; }
}

@media (min-width: 768px) {
    .about-3 {
        margin-top: 20px !important;
    }
}

@media (min-width: 1024px) {
    .about-3 {
        margin-top: 120px !important;
    }
}